import React from "react"
import {rhythm} from "../utils/typography";
import {Link} from "gatsby";

const AllTalks = () => (<div style={{margin: "20px 0 40px"}}>
    <div>
        <p style={{marginBottom: rhythm(1 / 4)}}>
            <Link style={{boxShadow: `none`, fontWeight: 500}} to={`https://youtu.be/Ee0QCyRuwCU`} target="_blank">
                REACT to Imposter Syndrome
            </Link> at RubyConf Australia 2023
        </p>
        <p style={{marginBottom: rhythm(1 / 4)}}>
            <Link style={{boxShadow: `none`, fontWeight: 500}} to={`https://techleaderseries.com/Register/l2kxZAl0ezs`} target="_blank">
                Faculty speaker
            </Link> at Tech Leader Series
        </p>
        <p style={{marginBottom: rhythm(1 / 4)}}>
            <Link style={{boxShadow: `none`, fontWeight: 500}} to={`https://youtu.be/l2kxZAl0ezs`} target="_blank">
                REACT to Imposter Syndrome
            </Link> workshop at Women Techmakers Montréal
        </p>
        <p style={{marginBottom: rhythm(1 / 4)}}>
            <Link style={{boxShadow: `none`, fontWeight: 500}} to={`https://youtu.be/RbB1XA4hm-4`} target="_blank">
                REACT to Imposter Syndrome
            </Link> 20 minute talk at AWS She Builds
        </p>
        <p style={{marginBottom: rhythm(1 / 4)}}>
            <Link style={{boxShadow: `none`, fontWeight: 500}} to={`https://youtu.be/lQZvNunKUwU`} target="_blank">
                REACT to Imposter Syndrome
            </Link> 45 minute talk at Tech Inclusion
        </p>
        <p style={{marginBottom: rhythm(1 / 4)}}>
            <Link style={{boxShadow: `none`, fontWeight: 500}} to={`https://youtu.be/m9EFaL_DRAk`} target="_blank">
                7 lessons in 7 years + a panel discussion on Finding Your Voice
            </Link> for Girl Geek Dinners
        </p>
        <p style={{marginBottom: rhythm(1 / 4)}}>
            <Link style={{boxShadow: `none`, fontWeight: 500}} to={`https://youtu.be/ISfT-eiLuWk`} target="_blank">
                In conversation
            </Link>  with Code Like a Girl
        </p>
        <p style={{marginBottom: rhythm(1 / 4)}}>
            I've also spoken at the &nbsp;
            <Link style={{boxShadow: `none`, fontWeight: 500}} to={`https://ghc.anitab.org/speaker/prakriti-mateti/`} target="_blank">
                Grace Hopper Celebration
            </Link>
            &nbsp; and DDD Melbourne.
            <br/>Please reach out on LinkedIn for speaking engagements.
        </p>
    </div>
</div>)

export default AllTalks
