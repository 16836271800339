import React from "react"
import {graphql, Link} from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "../components/button"
import Bio from "../components/bio"
import SearchPosts from "../components/searchPosts"
import AllTalks from "../components/talks";

class IndexPage extends React.Component {
    render() {
        const {data, navigate, location} = this.props
        const siteTitle = data.site.siteMetadata.title
        const posts = data.allMdx.edges
        const localSearchBlog = data.localSearchBlog

        return (<Layout location={this.props.location} title={siteTitle}>
            <SEO title="All posts"/>
            <Bio/>
            <h2 style={{fontWeight: 500, marginBottom: "50px"}}>Talks</h2>
            <AllTalks/>
            <h2 style={{fontWeight: 500}}>Posts</h2>
            <SearchPosts
                posts={posts}
                localSearchBlog={localSearchBlog}
                navigate={navigate}
                location={location}
            />
            {/*<Link to="/">*/}
            {/*    <Button marginTop="85px">Go Home</Button>*/}
            {/*</Link>*/}
        </Layout>)
    }
}

export default IndexPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    localSearchBlog {
      index
      store
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`